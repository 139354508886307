<template>
  <v-navigation-drawer v-model="drawer" app temporary>
    <v-list>
      <v-list-item link to="/" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("home.hero.home") }}</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/about" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-information </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("home.hero.aboutus") }}</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/projects" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-office-building</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("home.hero.projects") }}</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/contact" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("home.hero.contactus") }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "CoreDrawer",
  data: () => ({}),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit("setDrawer", value);
      },
    },
  },
};
</script>

<style scoped></style>
